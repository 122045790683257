// data.jsx

import React from 'react';
import { Box, VStack, HStack, Heading, Text, Flex, Image, Button, Spacer, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import lightcone_logo from './lightcone_cover.jpg';
import defi_tvl from './defi_tvl.png';
import trump_meme from './trump_meme_plot.png';

const Data = () => {
  return (
    <Box className="App">
      <Box
        as="nav"
        padding="1.5rem"
        bg="black"
        color="white"
      >
        <Flex align="center" justify="space-between" wrap="wrap">
          <Flex align="center" mr={5}>
            <Link to="/">
              <Image src={lightcone_logo} alt="Lightcone Logo" maxHeight="75px" />
            </Link>
          </Flex>
          <Spacer />
          <HStack spacing={4}>
            <Link to="/about">
              <Button 
                bg="black" 
                color="rgba(0, 200, 255, 0.7)" 
                border="2px solid rgba(0, 200, 255, 0.7)"
                _hover={{ 
                  bg: "black", 
                  color: "rgba(255, 105, 180, 0.7)",
                  border: "2px solid rgba(255, 105, 180, 0.7)"
                }}
              >
                About
              </Button>
            </Link>
            <Link to="/contact">
              <Button 
                bg="black" 
                color="rgba(0, 200, 255, 0.7)" 
                border="2px solid rgba(0, 200, 255, 0.7)"
                _hover={{ 
                  bg: "black", 
                  color: "rgba(255, 105, 180, 0.7)",
                  border: "2px solid rgba(255, 105, 180, 0.7)"
                }}
              >
                Contact Us
              </Button>
            </Link>
            <Link to="/data">  
              <Button 
                bg="black" 
                color="rgba(0, 200, 255, 0.7)" 
                border="2px solid rgba(0, 200, 255, 0.7)"
                _hover={{ 
                  bg: "black", 
                  color: "rgba(255, 105, 180, 0.7)",
                  border: "2px solid rgba(255, 105, 180, 0.7)"
                }}
              >
                Research
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
      <main style={{ backgroundColor: 'black', color: 'white', padding: '40px 0', minHeight: 'calc(100vh - 200px)' }}>
        <VStack spacing={6} align="center" mt={8} maxW="800px" mx="auto">
          <Heading as="h1" size="xl" color="rgba(0, 200, 255, 0.7)">
            January 2025 Crypto Market Highlights
          </Heading>
          <Text fontSize="md" textAlign="left">
            Published January 31, 2025
          </Text>
          <Text fontSize="md" textAlign="left">
            There's never a dull moment in digital assets. This month marked record-breaking volumes and performance milestones for Lightcone Trading. Below are some crypto market highlights as of January 2025:
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            1. DeFi’s Continued Innovation
          </Heading>
          <Text fontSize="md" textAlign="left">
            The growth of Hyperliquid, Base and Sui highlight the proliferation of decentralized finance. Hyperliquid continues to push the boundaries of permissionless trading, while hitting almost $20B in daily volume in January. We expect these protocols to be a central theme through 2025, particularly after the launch of HyperEVM.
          </Text>
          <Box>
            <Image 
              src={defi_tvl} 
              alt="defi_tvl" 
              width="100%" 
              maxW="800px" 
              height="auto"
            />
            <Text 
              fontSize="sm" 
              color="gray.400" 
              mt={2} 
              textAlign="center"
            >
              Total Value Locked (TVL) from 2024 to current for several of the popular chains.
            </Text>
          </Box>
          <Text fontSize="md" textAlign="left">
            The DeFi protocols are closer to the original Bitcoin ethos, removing the need for intermediaries and counterparties. On-chain exchanges such as Hyperliquid solve the issue of exchange counterparty risk and liquidity fragmentation.
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            2. Crypto-AI Synthesis
          </Heading>
          <Text fontSize="md" textAlign="left">
            There is growing buzz around the synthesis of cryptocurrency and Artificial Intelligence, primarily because financial rails in crypto are not <strong>Speciesist</strong>: AI agents can freely engage on-chain, paving the way for self-governing, autonomous applications. We’re closely following efforts by <strong>ai16z</strong> and <strong>Virtuals</strong> to harness this synergy, potentially reshaping everything from token governance to order execution. Many more projects are expected in this area as the growth of agents rises exponentially with decreasing model training and inference costs (e.g. <strong>DeepSeek</strong>).
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            3. Presidential Coins
          </Heading>
          <Text fontSize="md" textAlign="left">
            President Trump and the First Lady launched their own meme coins, which generated much controversy in the industry. However, the newly inaugurated administration signaled warmer attitudes toward digital assets. The launch of the Official Trump meme coin saw increased inflows from retail investors—many of whom were trading on-chain for the first time—to participate in the Trump and Melania memes. These shifts spurred larger allocations from traditional funds into crypto, fueling hopes for broader market participation and potentially smoother regulatory pathways.
          </Text>
          <Box>
            <Image 
              src={trump_meme} 
              alt="trump_meme" 
              width="100%" 
              maxW="800px" 
              height="auto"
            />
            <Text 
              fontSize="sm" 
              color="gray.400" 
              mt={2} 
              textAlign="center"
            >
              Prices against VWAP on Kraken since their launch.
            </Text>
          </Box>
          <Text fontSize="md" textAlign="left">
            We can see the initial volatilty of these launches and their respective deviations from the VWAP. Since, the tokens have shown decreasingly activity into the end of January.
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            4. Traditional Finance Embraces Crypto
          </Heading>
          <Text fontSize="md" textAlign="left">
            Institutional demand soared with the launch of BTC and ETH Spot ETFs, solidifying cryptocurrency as a must-have asset class. Robust inflows (especially in Bitcoin) underscored a growing belief that crypto has secured its place in mainstream portfolios. Further spot ETFs as well as CME futures are expected, likely starting with XRP and SOL, with speculation about DOGE and others to follow. Moreover, many U.S. states are voting on Strategic Bitcoin Reserves leading to speculation about a national SBR (in addition or conjunction with the digital asset holdings already announced via the recent Executive Order).
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            5. Stablecoins Taking Center Stage
          </Heading>
          <Text fontSize="md" textAlign="left">
            Stablecoin usage surged, with newcomers like <strong>USDe via Ethena</strong> capturing attention by capitalizing on funding rate differentials. As stablecoins remain the linchpin of DeFi, we anticipate more entrants offering fresh approaches to stability and yield.
          </Text>

          <Heading as="h2" size="lg" textAlign="left" w="100%" mt={8}>
            Looking Forward
          </Heading>
          <Text fontSize="md" textAlign="left">
            From expanded institutional participation to AI-driven smart contracts, 2024 has been a milestone year. At Lightcone Trading, we’re excited to see how these trends evolve—and we’ll remain at the forefront of innovation in high-frequency crypto trading. If qualified candidates are interested in joining our team, please reach out. We will be hiring data engineers, quantitative developers, and operations/BD in the year ahead!
          </Text>
        </VStack>
      </main>
      <footer style={{ backgroundColor: 'black', color: 'white', padding: '20px 0' }}>
        <HStack spacing={4} justify="center" mt={8} mb={4}>
          <Text color="white">Miami, FL, USA</Text>
        </HStack>
        <Text textAlign="center" fontSize="sm" color="gray.500">
          &copy; {new Date().getFullYear()} Lightcone Trading LLC
        </Text>
      </footer>
    </Box>
  );
};

export default Data;